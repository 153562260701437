.privacy-container {
    max-width: 1000px;
    margin: 0 auto;
    background-color: #ffffff; /* White background */
    padding: 40px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 40px; /* Space at the bottom to align with the footer */
  }
  
  .privacy-container h1 {
    color: #f58020;
    font-size: 32px;
    text-align: center;
    margin-bottom: 24px;
  }
  
  h2 {
    color: #f58020;
    font-size: 24px;
    margin-top: 24px;
  }
  
  p {
    font-size: 16px;
    margin: 12px 0;
  }
  
  ul {
    margin: 12px 0;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 8px;
  }
  