.payment-success-container {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 40px auto;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.success-title {
    font-size: 28px;
    font-weight: bold;
    color: #4CAF50; /* Green color */
    margin-bottom: 15px;
}

.welcome-note {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
}

.product-details-success {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.product-image-success {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
}

.product-info {
    text-align: left;
}

.product-title {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin: 10px 0;
}

.product-description {
    font-size: 14px;
    color: #777;
}

.download-btn {
    background-color: #f58020; /* Orange color */
    color: #fff;
    font-size: 18px;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 200px;
    margin-top: 20px;
}

.download-btn span {
    font-weight: bold;
}

.download-btn:hover {
    background-color: #d86919; /* Darker shade of orange */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.download-btn:active {
    transform: scale(0.98);
}


.payment-failure-container {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 40px auto;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.failure-title {
    font-size: 28px;
    font-weight: bold;
    color: #f44336; /* Red color for failure */
    margin-bottom: 15px;
}

.failure-note {
    font-size: 18px;
    color: #555;
    margin-bottom: 30px;
}

.failure-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.failure-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
}

.failure-info {
    text-align: left;
}

.failure-title {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin: 10px 0;
}

.failure-description {
    font-size: 14px;
    color: #777;
}

.retry-btn {
    background-color: #f44336; /* Red color for failure */
    color: #fff;
    font-size: 18px;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 200px;
    margin-top: 20px;
}

.retry-btn span {
    font-weight: bold;
}

.retry-btn:hover {
    background-color: #d32f2f; /* Darker shade of red */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.retry-btn:active {
    transform: scale(0.98);
}