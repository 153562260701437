/* Main container styling */
.product-list {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Filter section styling */
  .filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .filters input, .filters select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    flex: 1;
    min-width: 200px;
  }
  
  /* Product grid layout */
  .products {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Individual product box styling */
  .product-box {
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .product-box:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Product image styling */
  .product-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  /* Product details */
  .product-box h3 {
    font-size: 18px;
    margin: 10px;
    color: #333;
    text-align: center;
  }
  
  .product-box .price {
    font-size: 16px;
    margin: 0 10px;
    color: #666;
    text-align: center;
  }
  
  .product-box .discount-price {
    font-size: 18px;
    color: #f58020;
    font-weight: bold;
  }
  
  .product-box .original-price {
    font-size: 14px;
    color: #999;
    text-decoration: line-through;
    margin-left: 5px;
  }
  
  /* Buy button styling */
  .product-box button {
    display: block;
    width: 100px;
    border-radius: 25px !important;
    padding: 10px;
    margin: 10px;
    background-color: #f58020;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-box button:hover {
    background-color: #e36c00;
  }
  
  /* Pagination styling */
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin: 0 5px;
  }
  
  .pagination button.active, .pagination button:hover {
    background-color: #f58020;
    color: #fff;
  }
  
  .pagination button:disabled {
    background-color: #eee;
    color: #999;
    cursor: not-allowed;
  }
  
/* No records found styling */
.no-records {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px; /* Adjust the height as needed */
    text-align: center;
    background-color: #f9f9f9; /* Light background to make the content stand out */
    border: 2px dashed #ddd; /* Optional dashed border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    margin: 20px 0; /* Margin to add spacing */
  }
  
  .no-records img {
    width: 200px; /* Increased size for better visibility */
    height: auto;
    margin-bottom: 20px; /* Space between image and text */
  }
  
  .no-records p {
    font-size: 20px; /* Larger font size for better readability on desktop */
    color: #333;
    margin: 0; /* Remove default margin */
  }
  
  /* Responsive adjustments for mobile view */
  @media (max-width: 768px) {
    .no-records {
      height: 300px; /* Adjust height for mobile view */
      padding: 15px; /* Adjust padding for mobile */
    }
  
    .no-records img {
      width: 150px; /* Adjust image size for mobile */
    }
  
    .no-records p {
      font-size: 18px; /* Slightly smaller font size for mobile */
    }
  }
  
  /* Responsive adjustments for very small screens */
  @media (max-width: 480px) {
    .no-records {
      height: 250px; /* Further adjust height for very small screens */
      padding: 10px; /* Further adjust padding */
    }
  
    .no-records img {
      width: 120px; /* Adjust image size for very small screens */
    }
  
    .no-records p {
      font-size: 16px; /* Further adjust font size */
    }
  }
  
  
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .filters {
      flex-direction: column;
      gap: 10px;
    }
  
    .filters input, .filters select {
      max-width: 100%;
    }
  
    .products {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  
    .product-box {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
  
  /* Styling for <a> tags */
  .product-list a {
    text-decoration: none;
    color: inherit;
  }
  