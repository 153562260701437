.home-page {
  padding: 20px;
}

.welcome-section {
  text-align: center;
  margin-bottom: 20px;
}

.banner {
  margin-bottom: 20px;
}

.banner img {
  width: 100%;
  height: auto;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 20px;
}

.service {
  text-align: center;
  flex: 1 1 calc(33.333% - 20px);
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #ffffff;
}

.service-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  color: #7d4998;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 24px;
  margin: 0 auto 10px auto;
}

.service h3 {
  margin: 10px 0;
}

.trending-products, .discount-products {
  margin-bottom: 20px;
}

.products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
}

.product img {
  width: 100%;
  height: auto;
}

.price {
  color: #f58020;
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .service {
    flex: 1 1 100%;
  }

  .product {
    flex: 1 1 100%;
  }

  .carousel .slider-wrapper {
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel .slider-wrapper {
    height: 250px;
  }
}

.carousel .slider-wrapper {
  height: 300px;
}

.carousel .slide img {
  object-fit: cover;
}

.carousel .control-arrow, 
.carousel.carousel-slider .control-arrow {
  background: none;
  color: #f58020;
  font-size: 1.5rem;
}

.carousel .control-dots .dot {
  background-color: #f58020;
}
