.faq-container {
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .faq-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .faq-text {
    flex: 1;
    min-width: 300px;
  }
  
  .faq-images {
    flex: 1;
    text-align: center;
  }
  
  .faq-image {
    max-width: 100%;
    border-radius: 8px;
  }
  