.about-us-container {
    padding:20px;
    /* max-width: 1200px; */
    margin: 0 auto;
}

.about-us-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.about-us-text {
    flex: 1;
    margin-right: 20px;
}

.about-us-images {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-us-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-info {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .about-us-content {
        flex-direction: column;
        align-items: center;
    }

    .about-us-text {
        margin-right: 0;
        text-align: center;
    }

    .about-us-images {
        flex-direction: row;
        gap: 10px;
    }

    .about-us-image {
        max-width: 100%;
    }
}