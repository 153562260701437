/* Sales Banner styling */
.sales-banner {
    background: linear-gradient(90deg, rgba(255,87,34,1) 0%, rgba(255,193,7,1) 100%);
    color: #fff;
    padding: 15px 0;
    text-align: center;
    position: relative;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .banner-message {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0 20px;
    animation: fadeIn 1.5s ease-out;
  }
  
  .banner-button {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 25px;
    background-color: #fff;
    color: #f57c00;
    text-decoration: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .banner-button:hover {
    background-color: #f57c00;
    color: #fff;
    transform: scale(1.05);
  }
    
  /* Keyframes for text fade-in effect */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .banner-message {
      font-size: 20px;
    }
  
    .banner-button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-message {
      font-size: 18px;
    }
  
    .banner-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .info-text {
      font-size: 12px;
    }
  }
  