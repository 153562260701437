/* Container styling for TrustTag */
.trust-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 8px;
}

/* Flexbox alignment for the items */
.info-item {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    /* Ensures icons and text are in a row by default */
}

/* Icon styling */
.info-icon {
    font-size: 24px;
    color: #f58020;
}

/* Text styling */
.info-text {
    font-size: 16px;
    color: #333;
    margin: 0;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .trust-info {
        flex-direction: row;
        /* Stack items vertically on mobile */
        align-items: flex-start;
        /* Align items to the start */
        border: none;
        box-shadow: none;
        padding: 0px;
        gap: 0px;
    }

    .info-item {
        flex-direction: row;
        /* Ensure each tag remains in a row */
        justify-content: flex-start;
        width: 100%;
        /* Full width for each item */
        padding: 5px 0;
        /* Add spacing between items */
    }

    .info-icon {
        font-size: 20px;
    }

    .info-text {
        font-size: 14px;
    }
}


@media (max-width: 320px) {
    .mobile-header .logo-img {
        display: none;
    }
}

@media (max-width: 1024px) {
    .trust-info {
        margin-left: 5%;
    }
}