.product-page {
    padding: 20px;
    padding-top: 0px;
    font-family: Arial, sans-serif;
}

.product-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    /* Ensure proper wrapping on smaller screens */
}

.product-image-section {
    flex: 1;
    margin-right: 20px;
    max-width: 100%;
    /* Ensure it doesn't overflow */
    position: relative;
}

.product-image {
    width: 100%;
    max-width: 400px;
    /* Maximum width of the image */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
}

.image-slider {
    width: 100%;
    max-width: 500px;
    /* Adjust based on your design */
    margin-bottom: 20px;
}

.slick-slide {
    display: flex;
    justify-content: center;
}

.slick-prev,
.slick-next {
    z-index: 1;
}

.product-share {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.product-share a {
    margin-right: 10px;
    color: #007bff;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.product-share svg {
    font-size: 24px;
    margin-right: 5px;
}

.product-share .share-text {
    font-size: 14px;
    font-weight: 500;
    color: #007bff;
    margin-left: 5px;
    /* Space between icon and text */
}

.product-details-section {
    flex: 2;
}

.product-buy {
    /* margin-top: 20px; */
    display: flex;
}

.product-buy button {
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #f58020;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 15px;
    font-weight: bold !important;
    letter-spacing: 0.8px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* .product-buy button:hover {
    background-color: #d76817;
} */

.product-tags {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Space between tags */
}

.product-tag {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #f58020;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 15px;
    /* font-weight: bold; */
    letter-spacing: 0.8px;
}

.product-tag:hover {
    background-color: #d76817;
}

/* Social Media Icon Colors */
.facebook-icon svg {
    color: #1877F2;
    /* Facebook Blue */
}

.twitter-icon svg {
    color: #1DA1F2;
    /* Twitter Blue */
}

.linkedin-icon svg {
    color: #0077B5;
    /* LinkedIn Blue */
}

.whatsapp-icon svg {
    color: #25D366;
    /* WhatsApp Green */
}

/* Icon Hover Effect */
.product-share a:hover svg {
    opacity: 0.8;
    /* Slight transparency on hover */
}

.loaderBox {
    display: flex;
    justify-content: center;
    height: 500px;
}

.loaderBox img {
    width: 100%;
    height: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .product-container {
        flex-direction: column;
    }

    .product-image-section {
        margin-right: 0;
    }

    .product-share {
        justify-content: center;
        margin-top: 15px;
    }

    .product-share a {
        margin-right: 8px;
    }

    .product-details-section {
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .product-buy button {
        width: 100%;
        font-size: 14px;
    }

    .product-share .share-text {
        display: none;
        /* Hide share text on very small screens */
    }

    .product-share a {
        margin-right: 5px;
    }
}

.product-price {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    /* Color for the price */
}

.discount-price {
    font-size: 18px;
    font-weight: bold;
    color: #f58020;
    /* Discount price color */
    margin-top: 10px;
}

.original-price {
    font-size: 16px;
    color: #888;
    /* Original price color */
    text-decoration: line-through;
    /* Strike-through */
    margin-left: 10px;
}

.im-new-checkout {
    margin-bottom: 0px !important;
}

svg {
    cursor: none !important;
}