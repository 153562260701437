.sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #FFFFFF; /* White background for modern look */
    /* border-right: 1px solid #E0E0E0;  */
    /* background-image: url('https://app.placementmaster.in/sidebar.png') */
}

.sidebar-logo {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #F5F5F5; /* Light gray background for logo section */
    border-bottom: 1px solid #E0E0E0; /* Light gray border */
}

.logo-img {
    height: 40px; /* Logo height */
}

.logoText {
    font-size: 16px;
    margin-left: 15px;
    font-weight: bold;
    color: #f58020; /* Blue color for text */
    text-transform: uppercase; /* Uppercase text for a strong impression */
}

.sidebar-divider {
    margin: 8px 0;
}

.sidebar-menu-item, .sidebar-submenu-item {
    display: flex;
    align-items: center;
    padding: 12px 16px; /* Adjust padding for better spacing */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
    font-size: 16px; /* Font size for main menu items */
}

.sidebar-menu-item:hover, .sidebar-menu-item.active,
.sidebar-submenu-item:hover, .sidebar-submenu-item.active {
    background-color: #E3F2FD; /* Light blue background color */
    color: #f58020; /* Text color */
}

.sidebar-menu-item.active .MuiSvgIcon-root,
.sidebar-submenu-item.active .MuiSvgIcon-root {
    color: #f58020; /* Icon color */
}

.sidebar-menu-item .MuiSvgIcon-root,
.sidebar-submenu-item .MuiSvgIcon-root {
    font-size: 18px; /* Icon size */
}

.sidebar-submenu-item {
    padding-left: 32px; /* Indentation for sub-menu items */
    font-size: 14px; /* Font size for sub-menu items */
}

.powered-by {
    display: flex;
    justify-content: center;
    padding: 16px;
    margin-top: auto;
    font-size: 14px; /* Font size for 'Powered by' text */
    color: #44546F; /* Color for 'Powered by' text */
}

.mobile-header {
    display: flex;
    height: 50px;
    align-items: center; /* Center items vertically */
    padding: 12px 16px; /* Add padding for better spacing */
    /* background-color: #F5F5F5; Light gray background for header */
    /* border-bottom: 1px solid #E0E0E0; Subtle border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
    justify-content: space-between; /* Space out items */
}

.mobile-header .logo-img {
    height: 30px; /* Adjust logo size for header */
}

.mobile-header .menu-button {
    color: #f58020; /* Icon color */
    background: transparent;
    border: none;
    cursor: pointer;
}

.mobile-header .menu-button:hover {
    color: #003a8c; /* Darker shade on hover */
}
