.blog-content-creation {
    padding: 20px;
}

.form-preview-container {
    display: flex;
    gap: 20px;
}

.form-container {
    flex: 1;
}

.blog-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.tags-container {
    margin-top: 10px;
}

.preview-container {
    flex: 1;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.product-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product-description {
    margin: 10px 0;
}

.product-pricing {
    margin: 10px 0;
}

.discount-price {
    font-size: 1.5rem;
    color: #f58020;
    margin-right: 10px;
}

.original-price {
    text-decoration: line-through;
    color: #888;
}

.product-tags {
    margin: 10px 0;
}

.product-tag {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #f58020;
    color: #fff;
    border-radius: 15px;
}

.product-details-info p {
    margin: 10px 0;
}

.upload-container {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10%;
    width: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px auto;
    margin-bottom: 5%;
}