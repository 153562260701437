.footer {
  color: #44546f;
  padding: 40px 20px;
  /* border-top: 1px solid #e0e0e0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}

.footer-logo-description {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 20px;
}

.footer-logo-img {
  margin-right: 20px;
}

.footer-description {
  font-size: 14px;
  color: #44546f;
  max-width: 400px; /* Limit text width */
}

.footer-links {
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: space-between; /* Space out link sections */
  gap: 20px; /* Add gap between sections */
}

.footer-link-section {
  flex: 1;
  margin-bottom: 20px;
}

.footer-link-section h4 {
  margin-bottom: 10px;
  font-size: 16px;
}

.footer-link-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-link-section ul li {
  margin-bottom: 8px;
}

.footer-link-section ul li a {
  text-decoration: underline;
  color: #0052cc;
}

.footer-link-section ul li a:hover {
  text-decoration: none;
}

.footer-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px; /* Space between features */
}

.feature {
  flex: 1 1 calc(33.333% - 20px); /* Responsive sizing */
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for features */
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center; /* Center text within feature box */
}

.feature h4 {
  margin-bottom: 5px;
  font-size: 16px;
  color: #f58020; /* Accent color */
}

.feature p {
  font-size: 14px;
}

.footer-payment-methods {
  text-align: center;
  margin-top: 20px;
}

.footer-payment-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.payment-icon {
  height: 30px;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px; /* Add margin for separation */
}

.footer-social-media {
  margin-bottom: 10px;
}

.footer-social-media a {
  margin: 0 10px;
  color: #0052cc;
  text-decoration: none;
}

.footer-social-media a:hover {
  color: #003a8c;
}

.footer-copy {
  font-size: 14px;
}

.footer-bottom-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px; /* Add gap between elements */
}

@media (max-width: 768px) { 
  .footer-bottom-layout {
    flex-direction: column;
    gap: 20px; /* Adjust gap for mobile view */
  }
  
  .footer-features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    flex: 1 1 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .footer-logo-img {
    width: 100px;
  }
  
  .footer-description {
    font-size: 12px;
  }
  
  .footer-link-section h4 {
    font-size: 14px;
  }
  
  .footer-link-section ul li a {
    font-size: 12px;
  }

  .footer-copy {
    font-size: 12px;
  }
}
